import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"

const PaintingsPage = ({ data }) => {
  return (
    <Layout page="Paintings">
      <SEO title="Paintings" />
      <Slider items={data.allContentfulPaintings.edges} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulPaintings {
      edges {
        node {
          title
          material
          width
          height
          image {
            fluid(maxWidth: 1600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default PaintingsPage
